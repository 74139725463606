import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import suction_cum_jetting from "../../public/static/images/products/suction_cum_jetting.jpg"

function SuctionCumJetting() {

  const prodDetail = {
    "name": "SUCTION CUM JETTING",
    "detail": {
      "about" : ["Suction cum Jetting is a Combination Machine, which combines High Pressure Sewer Jetting Operation (JetMan) as well as Suction Operation with Blowback System (GullyMan)."],
      "advantages": [
        "Equipment filled with a high flow continuous rating vacuum blower.",
        "This machine can carry out both the operations of suction as well as jetting.",
        "It’s easy operations, low maintenance & high capacity Suction-jetting System helps in superior cleaning of sewer lines with high productivity & in very less time.",
        "Provision for manufacturing Suction and vacuum tank separately or combined on single vehicle."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Tank Size",
        "Sheet Material",
        "Thickness",
        "Vacuum Pump",
        "Jetting Pump",
        "Hoses",
        "Power Take Off",
        "Hydraulic Cylinder",
        "Hydraulic Pump",
        "Hydraulic Motor"
      ],
      "column2": [
        "IESS – M/SJM/SCJM",
        "7 Ton, 9 Ton, 12 Ton, 16 Ton, 25 Ton/trailer or Custmize etc.",
        "4000 Ltrs, 6000 Ltrs, (8-20 Kl or Customized Tanks)",
        "Mild Steel (IS : 2062)",
        "5 Mm, 6 Mm or as Per Requirement",
        "M/s Jurop, Italy / Battioni Pagani Italy / Eq. (5000 LPM –13000 LPM Capacity)",
        "M/s Pratissoli, Italy / Equivalent Make ( 70 –260 LPM) / (90 –150 Bar) Capacity Suction",
        "Hose Heavy Duty (upto 5” & 15 Mtrs) Jetting House High Pressure (upto1¼”&60mtrs) Washing Hose With Gun (½”&10mtrs) Optional",
        "Vas/eq. With Upto 400 kgm Capacity Suitable Power Transfer Ratio to Drive Pumps",
        "1 Nos. For Tank Tipping & 2 Nos. For Door Opening",
        "Gear Pump (40-80 LPM)",
        "60-160 CC"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={suction_cum_jetting} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default SuctionCumJetting
